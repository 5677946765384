/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-07 17:26:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-07 17:31:18
 */
export const columns = [
{
    title: '门店名称',
    dataIndex: 'storeName',
    key: 'storeName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '员工姓名',
    dataIndex: 'managerName',
    key: 'managerName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '目标(万元)',
    dataIndex: 'targetAmount',
    key: 'targetAmount',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '绩效时间',
    dataIndex: 'targetDate',
    key: 'targetDate',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
